import React, {useState, useEffect, useCallback} from "react";
import {
    Button,
    Form,
    useDataProvider,
    useNotify,
} from "react-admin";
import Resources from "../../Resources";
import {onError} from "../../common/utils";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useIntersectionObserver} from "../../common/hooks";
import ChatMessagesList from "./ChatMessagesList";
import ChatInputMessage from "./ChatInputMessage";

function ChatDialog(props) {
    const {onClose, isOpen, opportunity, loanApplication} = props;
    const [messages, setMessages] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const refreshMessages = useCallback((append) => {
        if(isLoading || (append && !nextToken)) {
            return;
        }

        setLoading(true);
        dataProvider['doAction'](Resources.CHAT.name, {
            action: Resources.CHAT.actions.MESSAGES.name,
            queryStr: `channelArn=${opportunity.chimeChannelArn}` + (append && nextToken ? `&nextToken=${nextToken}` : ''),
            method: 'GET'
        }).then(({data}) => {
            if(append) {
                setMessages([...messages, ...data.data.messages])
            } else {
                setMessages(data.data.messages);
            }
            setNextToken(data.data.nextToken)
        }).catch((error) => {
            onError(error, notify);
        }).finally(() => {
            setLoading(false);
        });
    }, [isLoading, notify, nextToken, opportunity.chimeChannelArn]);


    const lastElementRef = useIntersectionObserver(() => {
        refreshMessages(true);
    });


    useEffect(() => {
        refreshMessages(false);
    }, []);

    const [newMessage, setNewMessage] = useState('');

    const handleSendMessage = useCallback(() => {
        if (newMessage) {
            dataProvider['doBodyAction'](Resources.CHAT.name, {
                action: Resources.CHAT.actions.MESSAGES.name,
                method: "POST",
                body: {
                    content: newMessage,
                    channelArn: opportunity.chimeChannelArn
                },
            }).then(({data}) => {
                if (data.success) {
                    setNewMessage('');
                    refreshMessages(false);
                } else {
                    const msg =
                        data.errMessage
                            ? data.errMessage
                            : "System error";
                    onError({message: msg}, notify)
                }
            }).catch(error => {
                onError(error, notify);
            });

        }
    }, [setNewMessage, newMessage]);

    return (
        <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth="sm">
                <DialogTitle>Chat with {opportunity?.creditor?.fullCompanyName} and the borrower</DialogTitle>
                <DialogContent>
                    <ChatMessagesList messages={messages} lastElementRef={lastElementRef} />
                    <ChatInputMessage handleSendMessage={handleSendMessage} newMessage={newMessage} setNewMessage={setNewMessage} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} label="Close"/>
                </DialogActions>
        </Dialog>
    );
}

export default ChatDialog;