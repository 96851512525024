import * as React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {LoanApplicationsSummary} from "./view/dashboard/LoanApplicationsSummary";
import {DebtInvestorsSummary} from "./view/dashboard/DebtInvestorsSummary";
import {useTheme} from "react-admin";
import {LegalEntitiesSummary} from "./view/dashboard/LegalEntitiesSummary";

export const Dashboard = () => {
    const [theme] = useTheme();

    return (
        <div className={`dashboard dashboard-${theme}`}>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    Loan Applications Summary
                </AccordionSummary>
                <AccordionDetails>
                    <LoanApplicationsSummary/>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    Debt Investors Summary
                </AccordionSummary>
                <AccordionDetails>
                    <DebtInvestorsSummary/>
                </AccordionDetails>
            </Accordion>
{/*            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    Legal Entities Summary
                </AccordionSummary>
                <AccordionDetails>
                    <LegalEntitiesSummary/>
                </AccordionDetails>
            </Accordion>*/}
        </div>
    )
}
