import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {getFirstDayOfMonth} from "../../common/utils";
import Grid from "@mui/material/Grid";
import {LoanApplicationsByStatus} from "./LoanApplicationsByStatus";
import {LoanApplicationsByLoanType} from "./LoanApplicationsByLoanType";
import {DateInput, Form, SaveButton} from "react-admin";
import {CurrencyInput} from "../controls/CurrencyInput";
import {CurrencyType} from "../dicts/ExchangeEnums";

const LoanApplicationsFilterForm = (props) => {
    return (
        <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={3}>
                <CurrencyInput source="currency" required currencyTypeCode={CurrencyType.FIAT.code}/>
            </Grid>
            <Grid item xs={3}>
                <DateInput label="Created Date (from)" source="createdDateFrom" required/>
            </Grid>
            <Grid item xs={3}>
                <DateInput label="Created Date (to)" source="createdDateTo" required/>
            </Grid>
            <Grid item xs={1}>
                <SaveButton alwaysEnable color="primary" size="large" variant="contained" sx={{marginTop: "10px"}}
                            label="Apply"/>
            </Grid>
        </Grid>
    )
}

export const LoanApplicationsSummary = (props) => {
    const defaultValues = useMemo(
        () => ({currency: "USD", createdDateFrom: getFirstDayOfMonth(), createdDateTo: new Date()}),
        []
    )
    const [filterValues, setFilterValues] = useState(defaultValues)
    const onLoanApplicationsFilterSubmit = useCallback(
        (values) => {
            setFilterValues({...values})
        },
        [setFilterValues]
    );
    const validate = (form) => {
        const errors = {};
        if (!form.currency) {
            errors.currency = "Currency is required";
        }
        if (!form.createdDateFrom) {
            errors.createdDateFrom = "Created Date (From) is required";
        }
        if (!form.createdDateTo) {
            errors.createdDateTo = "Created Date (To) is required";
        }
        return errors;
    }

    return (
        <Form defaultValues={defaultValues} onSubmit={onLoanApplicationsFilterSubmit} validate={validate}>
            <Grid container columnSpacing={1} rowSpacing={1} className="dashboard-grid">
                <Grid item xs={11}>
                    <LoanApplicationsFilterForm/>
                </Grid>
                <Grid item xs={11}>
                    <LoanApplicationsByStatus filterValues={filterValues}/>
                </Grid>
                <Grid item xs={11}>
                    <LoanApplicationsByLoanType filterValues={filterValues}/>
                </Grid>
            </Grid>
        </Form>
    )
}