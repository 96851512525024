import BigNumber from "bignumber.js";
import {DICTS, MAX_CURRENCY_SCALE} from "./constants";

const displayedCurrencies = {
    "USD": "$",
    "GBP": "£",
    "EUR": "€",
    "CHF": "₣",
    "CAD": "C$",
    "AUD": "A$",
};

export const getValueByPath = (obj, path) => {
    if (obj) {
        let split = path.split('.');
        if (split.length > 1) {
            let value = obj;
            for (let i=0; i<split.length; i++) {
                value = value[split[i]]
            }
            return value;
        } else {
            return obj[path]
        }
    }

    return undefined;
}

export const formatAmount = (currency, amount, options) => {
    if (amount === undefined)
        return "";

    const x = new BigNumber(amount)
    const currencyLabel = displayedCurrencies[currency] || currency;

    const fractionDigits =
        DICTS.STORE_CURRENCY_SCALES_MAP
            ? DICTS.STORE_CURRENCY_SCALES_MAP[currency]
            : MAX_CURRENCY_SCALE
    let skipCurrencyLabel = options && options.skipCurrencyLabel;

    let v = (skipCurrencyLabel ? "" : (currencyLabel + " "));
    if ((options && options.maximumFractionDigits === 0) || fractionDigits === 0) {
        let fmt = {
            prefix: '',
            decimalSeparator: '.',
            groupSeparator: ',',
            groupSize: 3,
            secondaryGroupSize: 0,
            fractionGroupSeparator: ' ',
            fractionGroupSize: 0,
            suffix: ''
        }
        return v + x.toFormat(0, fmt);
    } else {
        return v + x.decimalPlaces(fractionDigits).toString();
    }
}

export const onError = (error, notify) => {
    // console.log("disburse.onError", error)
    // failure side effects go here
    let msg = ""
    if (error && error.response && error.response.data && error.response.data.success === false) {
        msg = error.response.data.errMessage
    } else {
        msg = error.message
    }
    if (notify) {
        notify(`(${error.code}) ${msg}`, {type: 'warning'});
    }
};

export function onSuccess(close, setSubmitting, refresh) {
    if(close) {
        close();
    };
    if(setSubmitting) {
        setSubmitting(false);
    }
    if(refresh) {
        refresh();
    }
};

export const toLondonDateTime = (v) => {
    return v ? new Date(v + (v.endsWith("Z") ? "" : "Z")).toLocaleString('en-GB', {timeZone: 'Europe/London'}) : ""
}

export const orElse = (v, defaultValue) => {
    return v ? v : defaultValue || '-'
}

export const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};

export const getDateMinusMonths = (countOfMonths) => {
    let d = new Date()
    d.setMonth(d.getMonth() - countOfMonths)
    return d
}

export const getFirstDayOfMonth = () => {
    let d = new Date()
    d.setDate(1)
    return d
}

export const toISODate = (d) => {
    return d?.toISOString ? d.toISOString().split("T")[0] : d
}

export const haveRole = (permissions, role) => {
    return permissions && permissions['cognito:groups']?.includes(role)
}

export const getHumanReadableAmount = (amount, currency, longLabel) => {
    if (amount === undefined)
        return "";

    const currencyLabel = displayedCurrencies[currency] || currency + ' ';
    const number = Math.round(amount);
    if (number >= 1000000000) {
        return `${currencyLabel}${Math.round(number / 1000000000)}${longLabel ? " billion" : "b"}`;
    }

    if (number >= 1000000) {
        return `${currencyLabel}${Math.round(number / 1000000)}${longLabel ? " million" : "m"}`;
    }

    if (number >= 1000) {
        return `${currencyLabel}${Math.round(number / 1000)}K`;
    }

    return `${currencyLabel}${number}`;
}