import React from "react";
import {Button, useDataProvider, useNotify} from "react-admin";
import {useMutation} from "@tanstack/react-query";
import Resources from "../../Resources";

function InitiateChimeChatButton(props) {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const { mutate, isPending, data } = useMutation({
        mutationFn: () => dataProvider.doAction(Resources.CHAT.name, {
            action: Resources.CHAT.actions.INITIATE_CHIME_CHAT.name,
            method: "POST"
        }),

    });

    function onSuccess(data) {
        console.log(data);
        notify(`Chime initialization completed. Users created: ${data?.data?.data?.usersCreated}, channels created: ${data?.data?.data?.channelsCreated}`,
            {type: 'success'});
    }

    function onError(err) {
        console.log(err);
        notify(`Error during initiation: ${err?.message}`, {type: "error"});
    }

    return (
        <Button onClick={() => mutate(null, {onSuccess, onError})} disabled={isPending}>Setup Chat</Button>
    );
}

export default InitiateChimeChatButton;