const Resources = {
    LEGAL_ENTITY: {
        name: "legalEntities",
        singleLabel: "Legal Entity",
        multiLabel: "Legal Entities",
        actions: {
            FIND_BY_NAME: {
                name: "findByName"
            },
            FIND_BY_IDS: {
                name: "findByIDs"
            },
            MERGE: {
                name: "merge"
            },
            FULL_INFO: {
                name: "full"
            }
        }
        ,
        subrequests: {
            PERSONS: {
                name: "persons"
            },
            APPLICATIONS: {
                name: "applications"
            },
            CALLS: {
                name: "calls"
            },
        }
    },
    LEAD_REQUESTS: {
        name: "leadRequests"
    },
    OPPORTUNITIES: {
        name: "fundingOpportunities"
    },
    LOAN_APPLICATIONS: {
        name: "loanApplications",
        actions: {
            DOCUMENTS: {
                name: "documents",
                subrequests: {
                    APPROVE_DELETE_REQUEST: {
                        name: "approveDeleteRequest"
                    },
                    REJECT_DELETE_REQUEST: {
                        name: "rejectDeleteRequest"
                    },
                }
            },
            ONE_PAGER: {
                name: "onePager"
            },
            REJECT: {
                name: "reject"
            },
            CAPITAL_SOURCING: {
                name: "capitalSourcing"
            },
            FUND: {
                name: "fund"
            },
            APPEND_CREDITORS: {
                name: "appendCreditors"
            },
            NDA: {
                name: "nda"
            },
            CONFIRM_LOI: {
                name: "confirmLoI"
            },
            DECLINE: {
                name: "decline"
            },
            RENEW: {
                name: "renew"
            },
            FIND_BY_NAME: {
                name: "findByName"
            },
            FIND_BY_IDS: {
                name: "findByIDs"
            },
            INACTIVATE: {
                name: "inactivate"
            },
        },
        subrequests: {
            CREDITOR_RELS: {
                name: "creditorRels"
            },
            CREDITORS: {
                name: "creditors"
            },
            TERM_SHEET: {
                name: "termSheet",
                subrequests: {
                    VERIFY: {
                        name: "verify"
                    }
                }
            },
            DATA_ROOM: {
                name: "dataRoom",
                subrequests: {
                    AUDIT: {
                        name: "audit"
                    }
                }
            },
        }
    },
    CREDITOR_APPLICATIONS: {
        name: "creditorApplications",
        actions: {
            DEACTIVATE: {
                name: "deactivate"
            }
        },
        subrequests: {
            CREDITOR_RELS: {
                name: "creditorRels"
            },
            RELEVANT_LOAN_APPLICATIONS: {
                name: "relevantLoanApplications"
            },
        }
    },
    CREDITORS: {
        name: "creditors",
        actions: {
            FIND_APPLICABLE_FOR_LOAN_APPLICATION: {
                name: "findApplicableForLoanApplication"
            },
            FIND_NON_VERIFIED_BY_NAME: {
                name: "findNonVerifiedByName"
            }
        },
        subrequests: {
            FUNDING_CRITERIAS: {
                name: "fundingCriterias"
            }
        }
    },
    CALL_REPORT: {
        name: "callReports",
        singleLabel: "Meeting Notes",
        multiLabel: "Meeting Notes"
    },
    PERSONS: {
        name: "persons",
        singleLabel: "Person",
        multiLabel: "Persons",
        subrequests: {
            CALLS: {
                name: "calls"
            },
        }
    },
    ACCOUNTS: {
        name: "accounts",
        singleLabel: "Account",
        multiLabel: "Accounts"
    },
    OPERATIONS: {
        name: "operations",
        singleLabel: "Operation",
        multiLabel: "Operations",
        actions: {
            AVAILABLE_PARTIES_FOR_DEPOSIT: {
                name: "availablePartiesForDeposit"
            }
        }
    },
    DEPOSIT_NOTIFICATIONS: {
        name: "depositNotifications",
        singleLabel: "Deposit Notification",
        multiLabel: "Deposit Notifications"
    },
    LOANS: {
        name: "loans",
        actions: {
            CALC_LTV: {
                name: "calcLTV"
            },
            PAYMENT_DETAILS: {
                name: "paymentDetails"
            },
            COLLATERALS: {
                name: "collaterals"
            },
            LIQUIDATE_COLLATERAL: {
                name: "liquidateCollateral"
            },
        }
    },
    PARTY_PARTY_REL: {
        name: "partyPartyRel",
        singleLabel: "Party Relation",
        multiLabel: "Party Relations",
    },
    DICT_LOAN_TYPES: {
        name: "dicts/customer/loanTypes"
    },
    DICT_BUSINESS_CATEGORIES: {
        name: "dicts/customer/businessCategories"
    },
    DICT_ORIGINATION_SOURCES: {
        name: "dicts/customer/originationSources"
    },
    DICT_CALL_REPORT_TEMPLATES: {
        name: "dicts/customer/callReportTemplates"
    },
    DICT_DOC_TYPES: {
        name: "dicts/customer/docTypes"
    },
    DICT_LEGAL_FORMS: {
        name: "dicts/customer/legalForms"
    },
    DICT_REQUEST_TYPES: {
        name: "dicts/customer/requestTypes"
    },
    USER_COLLATERAL_TYPES: {
        name: "dicts/customer/userCollateralTypes"
    },
    DICT_LOAN_STATUSES: {
        name: "dicts/customer/loanStatuses"
    },
    DICT_ASSET_CLASSES: {
        name: "dicts/customer/assetClasses"
    },
    DICT_BORROWER_TYPES: {
        name: "dicts/customer/borrowerTypes"
    },
    DICT_INDICATIVE_RETURNS: {
        name: "dicts/customer/indicativeReturns"
    },
    DICT_SECTORS: {
        name: "dicts/customer/sectors"
    },
    DICT_USE_OF_FUNDS: {
        name: "dicts/customer/usesOfFunds"
    },
    DICT_AUDIT_EVENT_TYPES: {
        name: "dicts/customer/auditEventTypes"
    },
    DICT_ASSETS_UNDER_MGMT: {
        name: "dicts/customer/assetsUnderMgmt"
    },
    DICT_CURRENCIES: {
        name: "dicts/exchange/currencies"
    },
    DICT_MANUAL_CURRENCIES: {
        name: "dicts/exchange/manualCurrencies"
    },
    DICT_LOAN_COLLATERAL_CURRENCIES: {
        name: "dicts/customer/loanCollateralCurrencies"
    },
    DICT_LOAN_CURRENCIES: {
        name: "dicts/customer/loanCurrencies"
    },
    DICT_OPERATION_TYPES: {
        name: "dicts/account/operationTypes"
    },
    DICT_ACCOUNT_PURPOSE_TYPE: {
        name: "dicts/account/accountPurposeTypes"
    },
    DICT_ACCOUNT_DETAILS: {
        name: "admin/dicts/account/details",
        singleLabel: "Deposit Account Details",
        multiLabel: "Dicts/Deposit Accounts Details",
    },
    DICT_BUSINESS_STAGES: {
        name: "dicts/customer/businessStages"
    },
    DICT_LOAN_STRUCTURES: {
        name: "dicts/customer/loanStructures"
    },
    DICT_REGIONS: {
        name: "dicts/customer/regions"
    },
    EXT_API: {
        name: "dicts/customer/extApis"
    },
    CURRENT_SPOTS: {
        name: "spots"
    },
    HISTORY_SPOTS: {
        name: "spots/history"
    },
    COINBASE_TRANSACTIONS: {
        name: "coinbase/transactions",
        singleLabel: "Transaction",
        multiLabel: "Transactions"

    },
    SETTINGS: {
        name: "settings",
        settings: {
            DISBURSE_LOAN_LTV_LIMIT: "DISBURSE_LOAN_LTV_LIMIT",
            DISBURSE_LOAN_ORIGINATION_FEE: "DISBURSE_LOAN_ORIGINATION_FEE"
        }
    },
    REPORTS: {
        name: "reports",
        singleLabel: "Report",
        multiLabel: "Reports",
        actions: {
            AVAILABLE_REPORTS: {
                name: "availableReports"
            },
            DOWNLOAD: {
                name: "content"
            }
        }
    },
    USERS: {
        name: "users"
    },
    AUDIT: {
        name: "audit",
    },
    DASHBOARD: {
      LOAN_APPLICATIONS_SUMMARY_BY_STATUS: {
          name: "/dashboard/loanApplicationsByStatus"
      },
      LOAN_APPLICATIONS_DETAILS_BY_STATUS: {
          name: "/dashboard/loanApplicationsDetailsByStatus"
      },
      LOAN_APPLICATIONS_SUMMARY_BY_LOAN_TYPE: {
          name: "/dashboard/loanApplicationsByLoanType"
      },
      DEBT_INVESTORS_MONTHLY_INCREASE_BY_CATEGORY: {
          name: "/dashboard/debtInvestors/monthlyIncreaseByCategory"
      },
      DEBT_INVESTORS_MONTHLY_INCREASE_BY_CHANNEL: {
          name: "/dashboard/debtInvestors/monthlyIncreaseByChannel"
      },
      DEBT_INVESTORS_MONTHLY_INCREASE_BY_AUM: {
          name: "/dashboard/debtInvestors/monthlyIncreaseByAum"
      },
      DEBT_INVESTORS_MONTHLY_INCREASE_BY_AUM_CUMULATIVE: {
          name: "/dashboard/debtInvestors/monthlyIncreaseByAumCumulative"
      },
      DEBT_INVESTORS_MONTHLY_INCREASE_BY_COUNTRY: {
          name: "/dashboard/debtInvestors/monthlyIncreaseByCountry"
      },
      DEBT_INVESTORS_MONTHLY_INCREASE_BY_COUNTRY_CUMULATIVE: {
          name: "/dashboard/debtInvestors/monthlyIncreaseByCountryCumulative"
      },
      DEBT_INVESTORS_MONTHLY_INCREASE_BY_CATEGORY_CUMULATIVE: {
          name: "/dashboard/debtInvestors/monthlyIncreaseByCategoryCumulative"
      },
      DEBT_INVESTORS_MONTHLY_INCREASE_BY_CHANNEL_CUMULATIVE: {
          name: "/dashboard/debtInvestors/monthlyIncreaseByChannelCumulative"
      },
      LEGAL_ENTITIES_SUMMARY_BY_AUM: {
          name: "/dashboard/legalEntitiesByAum"
      },
    },
    CHAT: {
        name: "chat",
        singleLabel: "Chat",
        multiLabel: "Chat",
        actions: {
            MESSAGES: {
                name: "messages"
            },
            INITIATE_CHIME_CHAT: {
                name: "initiate"
            },
        }
    },
}

export default Resources;
