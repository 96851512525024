import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {onError, toISODate} from "../../common/utils";
import Grid from "@mui/material/Grid";
import {DateInput, Form, SaveButton, useDataProvider, useNotify} from "react-admin";
import {CurrencyInput} from "../controls/CurrencyInput";
import {CurrencyType} from "../dicts/ExchangeEnums";
import Resources from "../../Resources";
import {MonthlyVolumeOfNewDeals} from "./MonthlyVolumeOfNewDeals";
import {MonthlyVolumeOfNewDealsByStatus} from "./MonthlyVolumeOfNewDealsByStatus";
import {BEGIN_DATE_1_AUG_2023} from "../../common/constants";

const LoanApplicationsFilterForm = (props) => {
    return (
        <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={3}>
                <CurrencyInput source="currency" required currencyTypeCode={CurrencyType.FIAT.code}/>
            </Grid>
            <Grid item xs={3}>
                <DateInput label="Created Date (from)" source="createdDateFrom" required/>
            </Grid>
            <Grid item xs={3}>
                <DateInput label="Created Date (to)" source="createdDateTo" required/>
            </Grid>
            <Grid item xs={1}>
                <SaveButton alwaysEnable color="primary" size="large" variant="contained" sx={{marginTop: "10px"}}
                            label="Apply"/>
            </Grid>
        </Grid>
    )
}

export const LoanApplicationsSummaryNew = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const defaultValues = useMemo(
        () => ({currency: "USD", createdDateFrom: BEGIN_DATE_1_AUG_2023, createdDateTo: new Date()}),
        []
    )
    const [filterValues, setFilterValues] = useState(defaultValues)
    const [loansDetailsByStatus, setLoansDetailsByStatus] = useState(null);
    const onLoanApplicationsFilterSubmit = useCallback(
        (values) => {
            setFilterValues({...values})
            loadLoanApplicationsDetailsByStatus(
                values.currency, values.createdDateFrom, values.createdDateTo
            )
        },
        [setFilterValues]
    );
    const validate = (form) => {
        const errors = {};
        if (!form.currency) {
            errors.currency = "Currency is required";
        }
        if (!form.createdDateFrom) {
            errors.createdDateFrom = "Created Date (From) is required";
        }
        if (!form.createdDateTo) {
            errors.createdDateTo = "Created Date (To) is required";
        }
        return errors;
    }

    useEffect(() => {
        loadLoanApplicationsDetailsByStatus(
            filterValues.currency, filterValues.createdDateFrom, filterValues.createdDateTo
        )
    }, [filterValues]);

    function loadLoanApplicationsDetailsByStatus (currency, createdDateFrom, createdDateTo) {
        // setLoading(true)
        dataProvider.doAction(Resources.DASHBOARD.LOAN_APPLICATIONS_DETAILS_BY_STATUS.name, {
            method: 'GET',
            queryStr: `currency=${currency}&createdDateFrom=${toISODate(createdDateFrom)}&createdDateTo=${toISODate(createdDateTo)}`
        })
            .then(({data}) => {
                // console.log("data", data);
                setLoansDetailsByStatus(data.data)
                // setLoading(false)
            })
            .catch((err) => {
                setLoansDetailsByStatus(null)
                // setLoading(false)
                onError(err, notify)
            });
    }

    return (
        <Form defaultValues={defaultValues} onSubmit={onLoanApplicationsFilterSubmit} validate={validate}>
            <Grid container columnSpacing={1} rowSpacing={1} className="dashboard-grid">
                <Grid item xs={11}>
                    <LoanApplicationsFilterForm/>
                </Grid>
                <Grid item xs={6}>
                    <MonthlyVolumeOfNewDeals data={loansDetailsByStatus}
                                             currency={filterValues.currency}
                                             label="Monthly volume of new deals"/>
                </Grid>
                <Grid item xs={6}>
                    <MonthlyVolumeOfNewDeals data={loansDetailsByStatus}
                                             cumulative
                                             currency={filterValues.currency}
                                             label="Cumulative Monthly volume of new deals"/>
                </Grid>
                <Grid item xs={6}>
                    <MonthlyVolumeOfNewDealsByStatus
                                             data={loansDetailsByStatus}
                                             currency={filterValues.currency}
                                             label="Monthly volume of new deals by application status"/>
                </Grid>
                <Grid item xs={6}>
                    <MonthlyVolumeOfNewDealsByStatus
                                             data={loansDetailsByStatus}
                                             cumulative
                                             currency={filterValues.currency}
                                             label="Cumulative Monthly volume of new deals by application status"/>
                </Grid>
            </Grid>
        </Form>
    )
}