import * as React from "react";
import {
    CreateButton,
    Datagrid,
    Edit,
    EditButton, FileField, FileInput, FunctionField,
    List,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar, UrlField, useDataProvider, useEditContext, useNotify, useRefresh, useRedirect, useTheme
} from 'react-admin';
import {AdminPortalRoles} from "./dicts/Security";
import {CheckPermission} from "./security/CheckPermission";
import {SettingTypeEnum} from "./dicts/SettingTypeEnum";
import Resources from "../Resources";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InitiateChimeChatButton from "./chat/InitiateChimeChatButton";

const ListActions = () => (
    <TopToolbar>
        <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
            <CreateButton/>
            <InitiateChimeChatButton />
        </CheckPermission>
    </TopToolbar>
);

export const SettingList = (props) => (
    <List {...props} actions={<ListActions/>} sort={{field: "id", order: "ASC"}} perPage={50}>
        <Datagrid rowClick={false} bulkActionButtons={false}>
            <TextField source="id"/>
            <FunctionField label="Value" render={(record) => {
                if (record.typeCode === SettingTypeEnum.DOCUMENT.code) {
                    return record.document
                        ? <UrlField
                            href={`/api/v1/settings/${record.id}/documentContent`}
                            label="File Name" source="document.filename" target="_blank"/>
                        : "-"
                } else {
                    return <TextField source="value"/>
                }
            }}/>
            <TextField source="description"/>
            <TextField source="category"/>
            <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
                <EditButton/>
            </CheckPermission>
        </Datagrid>
    </List>
);

const EditToolbar = props => {
    return <Toolbar {...props}>
        <CheckPermission role={AdminPortalRoles.BACKOFFICE_ADMIN}>
            <SaveButton/>
        </CheckPermission>
    </Toolbar>
};

export const SettingEdit = (props) => {
    const [submitting, setSubmitting] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const validateFile = (form) => {
        const errors = {};
        if (form.typeCode === SettingTypeEnum.DOCUMENT && !form.document) {
            errors.document = "Document for this setting is required";
        }
        return errors;
    }

    const onSettingSubmit = (data) => {
        setSubmitting(true);
        // console.log('onCapitalSourcingSubmit', data)
        dataProvider['doUpload'](Resources.SETTINGS.name, {
            id: data.id,
            method: 'PUT',
            body: data,
        }).then(({data}) => {
            if (data.success) {
                onSettingSuccess(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => onError(error, notify));
    };

    const onSettingSuccess = (data) => {
        // console.log("capitalSourcing.onSuccess", data)
        setSubmitting(false);
        notify(
            'Setting successfully stored',
            {type: 'success', autoHideDuration: 2000}
        );
        redirect(`/${Resources.SETTINGS.name}`);
    };

    const onError = (error) => {
        // console.log("onError", error)
        // failure side effects go here
        let msg = ""
        if (error && error.response && error.response.data && error.response.data.success === false) {
            msg = error.response.data.errMessage
        } else {
            msg = error.message
        }
        notify(`(${error.code}) ${msg}`, {type: 'warning'});
        setSubmitting(false);
    };

    return (
        <Edit {...props} redirect="list">
            <SimpleForm toolbar={<EditToolbar/>} onSubmit={onSettingSubmit} validate={validateFile}>
                <TextInput source="id" name="id" required readOnly/>
                <EditValue {...props}/>
                <TextInput source="description" name="description"/>
            </SimpleForm>
        </Edit>
    )
}

const EditValue = (props) => {
    const {record, isLoading} = useEditContext();
    const [fileChanged, setFileChanged] = useState(false);
    const [theme] = useTheme();

    if (isLoading) return null;

    const getFileElem = (record) => {
        return <Grid spacing={1} container>
            <Grid item xs={2}>
                <Typography className={`form-field-label form-field-label-${theme}`}>Currently stored in database:</Typography>
            </Grid>
            <Grid item xs={9}>
                <UrlField
                    href={`/api/v1/settings/${record.id}/documentContent`}
                    label="document.filename" source="document.filename" target="_blank"/>
            </Grid>
            <Grid item xs={12}>
                <FileInput source="document" multiple={false} isRequired onChange={fileOnChange}>
                    {fileChanged && <FileField source="src" title="title" target="_blank"/>}
                </FileInput>
            </Grid>
        </Grid>
    }

    const fileOnChange = (e) => {
        setFileChanged(e != null)
    }

    return (<>
            {record.typeCode === SettingTypeEnum.DOCUMENT.code
                && getFileElem(record)
            }
            {record.typeCode !== SettingTypeEnum.DOCUMENT.code
                && <TextInput source="value" name="value" required/>}
        </>
    )
}