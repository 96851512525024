import PropTypes from 'prop-types'
import React, {useState} from "react";
import Resources from "../../Resources";
import {
    BooleanField,
    Button,
    Confirm,
    Datagrid,
    FunctionField,
    List,
    TextField,
    TopToolbar,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRefresh
} from "react-admin";
import {PartyField} from "../controls/PartyField";
import Link from '@mui/material/Link';
import AppendCreditorsDialog from "./AppendCreditorsDialog";
import {LoanApplicationStatus} from "../dicts/LoanApplicationEnum";
import AuthenticatedBulkDeleteAction from "../controls/AuthenticatedBulkDeleteAction";
import EditNDAForLoanApplicationCreditorRelDialog from "./EditNDAForLoanApplicationCreditorRelDialog";
import EnumDictField from "../controls/EnumDictField";
import {LoanApplicationCreditorStatus} from "../dicts/LoanApplicationCreditorRelStatusEnum";
import {onError} from "../../common/utils";
import AppendNonVerifiedCreditorsDialog from "./AppendNonVerifiedCreditorsDialog";
import MergeDialog from "../legalEntities/MergeDialog";
import {NDAStatus} from "../dicts/NDAStatusEnum";
import PaginationBar from "../controls/PaginationBar";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {alpha, styled} from '@mui/material/styles';
import {useModal} from "../../common/hooks";
import CreateTermSheetDialog from "./CreateTermSheetDialog";
import ChatDialog from "../chat/ChatDialog";

function LoanApplicationCreditorRelList(props) {

    const {loanApplication} = props;
    const [appendCreditorsDialogOpened, setAppendCreditorsDialogOpened] = useState(false);
    const [appendNonVerifiedCreditorsDialogOpened, setAppendNonVerifiedCreditorsDialogOpened] = useState(false);
    const [editNDADialogOpened, setEditNDADialogOpened] = useState(false);
    const [relation, setRelation] = useState(null);
    const [confirmLoIOpened, setConfirmLoIOpened] = useState(false);
    const [confirmCreditorActionOpened, setConfirmCreditorActionOpened] = useState(false);
    const [creditorActionCode, setCreditorActionCode] = useState(null);
    const [confirmLoILabel, setConfirmLoILabel] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const [isMergeDialogOpened, setIsMergeDialogOpened] = useState(false);

    const [ChatModalDialog, showChatModalDialog] = useModal(ChatDialog, {loanApplication})


    const openMergeDialog = (record) => {
        setRelation(record)
        setIsMergeDialogOpened(true);
    };

    const closeMergeDialog = () => {
        setIsMergeDialogOpened(false);
    };

    const openAppendCreditorsDialog = () => {
        setAppendCreditorsDialogOpened(true);
    }

    const closeAppendCreditorsDialog = () => {
        setAppendCreditorsDialogOpened(false);
    }

    const openAppendNonVerifiedCreditorsDialog = () => {
        setAppendNonVerifiedCreditorsDialogOpened(true);
    }

    const closeAppendNonVerifiedCreditorsDialog = () => {
        setAppendNonVerifiedCreditorsDialogOpened(false);
    }

    const openEditNDADialog = (record) => {
        setRelation(record)
        setEditNDADialogOpened(true);
    }

    const closeEditNDADialog = () => {
        setEditNDADialogOpened(false);
    }

    function CreditorListActions(props) {
        return (
            <>
                {loanApplication.statusCode === LoanApplicationStatus.SOURCING_OF_CAPITAL.code && <TopToolbar>
                    <Button label="Add Debt Investors" onClick={openAppendCreditorsDialog} />
                    <Button label="Add Non Verified Debt Investors" onClick={openAppendNonVerifiedCreditorsDialog} />
                </TopToolbar>}
            </>
        );
    }

    function BulkActionButtons(props) {
        return (
            <>
                {loanApplication.statusCode === LoanApplicationStatus.SOURCING_OF_CAPITAL.code && <AuthenticatedBulkDeleteAction />}
            </>
        );
    }

    const handleLoIDialogClose = () => {
        setConfirmLoIOpened(false);
        setRelation(null);
    }
    const handleLoIConfirm = () => {
        setSubmitting(true);
        dataProvider['doBodyAction'](Resources.LOAN_APPLICATIONS.name, {
            action: Resources.LOAN_APPLICATIONS.actions.CONFIRM_LOI.name,
            subres: Resources.LOAN_APPLICATIONS.subrequests.CREDITORS.name,
            subresId: relation.creditorId,
            id: relation.loanApplicationId,
            method: 'PUT',
            body: {
                value: !!!relation.loiIsAccepted,
            },
        }).then(({data}) => {
            setSubmitting(false);
            setConfirmLoIOpened(false);

            if (data.success) {
                refresh();
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
            setSubmitting(false);
            setConfirmLoIOpened(false);
        });
    }
    const onEditLoIClick = (record) => {
        setRelation(record);
        setConfirmLoILabel(record.loiIsAccepted === true ? "remove" : "grant")
        setConfirmLoIOpened(true);
    }

    const onCreditorActionButtonClick = (record, actionCode) => {
        setRelation(record)
        setCreditorActionCode(actionCode)
        setConfirmCreditorActionOpened(true)
    }
    const onCreditorActionClose = () => {
        setRelation(null)
        setCreditorActionCode(null)
        setConfirmCreditorActionOpened(false)
    }

    const onCreditorActionConfirm = () => {
        setSubmitting(true);
        dataProvider['doAction'](Resources.LOAN_APPLICATIONS.name, {
            action: creditorActionCode,
            subres: Resources.LOAN_APPLICATIONS.subrequests.CREDITORS.name,
            subresId: relation.creditorId,
            id: relation.loanApplicationId,
            method: 'PUT',
        }).then(({data}) => {
            setSubmitting(false);
            setConfirmCreditorActionOpened(false)

            if (data.success) {
                refresh();
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
            setSubmitting(false);
            setConfirmCreditorActionOpened(false)
        });
    }

    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }));

    function ActionMenuButton(props) {
        const record = useRecordContext(props);
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <Button
                    id={`demo-customized-button-${record.id}`}
                    aria-controls={open ? `demo-customized-menu-${record.id}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    label="Actions"
                />
                <StyledMenu
                    id={`demo-customized-menu-${record.id}`}
                    MenuListProps={{
                        'aria-labelledby': `demo-customized-button-${record.id}`,
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {loanApplication.statusCode === LoanApplicationStatus.SOURCING_OF_CAPITAL.code
                        && record.statusCode !== LoanApplicationCreditorStatus.DECLINED.code
                        && (!loanApplication.isBorrowerConfirmationRequired || record.isConfirmedByBorrower) &&
                        <MenuItem onClick={() => {handleClose(); openEditNDADialog(record)}} disableRipple disabled={submitting}>
                            Edit NDA
                        </MenuItem>}
                    {loanApplication.statusCode === LoanApplicationStatus.SOURCING_OF_CAPITAL.code &&
                        (record.ndaStatusCode === NDAStatus.SIGNED.code
                            || record.ndaStatusCode === NDAStatus.NOT_REQUIRED.code) &&
                        record.isCreditorVerified &&
                        <MenuItem onClick={() => {handleClose(); onEditLoIClick(record);}} disableRipple
                                  disabled={submitting}>
                            {record.loiIsAccepted === true ? "Remove Access to Full DD" : "Access to Full DD"}
                        </MenuItem>}
                    {loanApplication.statusCode === LoanApplicationStatus.SOURCING_OF_CAPITAL.code
                        && (record.statusCode ===  LoanApplicationCreditorStatus.NEW.code
                            || record.statusCode ===  LoanApplicationCreditorStatus.DUE_DILIGENCE.code)
                        && <MenuItem onClick={
                                        () => {
                                            handleClose();
                                            onCreditorActionButtonClick(record, Resources.LOAN_APPLICATIONS.actions.DECLINE.name)
                                        }}
                                     disableRipple disabled={submitting}>
                            Decline
                    </MenuItem>}
                    {loanApplication.statusCode === LoanApplicationStatus.SOURCING_OF_CAPITAL.code
                        && record.statusCode ===  LoanApplicationCreditorStatus.DECLINED.code
                        && <MenuItem onClick={
                                        () => {
                                            handleClose();
                                            onCreditorActionButtonClick(record, Resources.LOAN_APPLICATIONS.actions.RENEW.name)
                                        }}
                                     disableRipple disabled={submitting}>
                            Renew
                    </MenuItem>}
                    <MenuItem onClick={() => {handleClose(); openMergeDialog(record);}}
                              disableRipple disabled={submitting}>
                        Replace
                    </MenuItem>
                </StyledMenu>
            </div>
        );
    }

    function actionToStatus(action) {
        switch (action) {
            case Resources.LOAN_APPLICATIONS.actions.RENEW.name: return "New"
            case Resources.LOAN_APPLICATIONS.actions.DECLINE.name: return "Declined"
        }
        return ""
    }

    return (
        <>
            {appendCreditorsDialogOpened && <AppendCreditorsDialog close={closeAppendCreditorsDialog} isOpened={appendCreditorsDialogOpened} loanApplication={loanApplication} />}
            {appendNonVerifiedCreditorsDialogOpened && <AppendNonVerifiedCreditorsDialog close={closeAppendNonVerifiedCreditorsDialog} isOpened={appendNonVerifiedCreditorsDialogOpened} loanApplication={loanApplication} />}
            {editNDADialogOpened && <EditNDAForLoanApplicationCreditorRelDialog close={closeEditNDADialog} isOpened={editNDADialogOpened} record={relation}  />}
            {confirmLoIOpened && <Confirm
                isOpen={confirmLoIOpened}
                title={"Letter of Intent confirmation for " + relation?.creditor?.fullCompanyName}
                content={`Are you sure you want to ${confirmLoILabel} access to Full DD?`}
                onConfirm={handleLoIConfirm}
                onClose={handleLoIDialogClose}
            />}
            {confirmCreditorActionOpened && <Confirm
                isOpen={confirmCreditorActionOpened}
                title={`Mark Debt Investor as ${actionToStatus(creditorActionCode)}`}
                content={`Are you sure you want to ${creditorActionCode} the Debt Investor?`}
                onConfirm={onCreditorActionConfirm}
                onClose={onCreditorActionClose}
            />}
            {isMergeDialogOpened && <MergeDialog open={isMergeDialogOpened} close={closeMergeDialog} sourceId={relation?.creditorId}/>}
            <List actions={<CreditorListActions />} empty={<CreditorListActions />} disableSyncWithLocation={true}
                  resource={Resources.LOAN_APPLICATIONS.name + "/" + loanApplication.id + "/" + Resources.LOAN_APPLICATIONS.subrequests.CREDITOR_RELS.name}
                  pagination={<PaginationBar/>} perPage={50}
            >
                <Datagrid rowClick={false} bulkActionButtons={<BulkActionButtons />}>
                    <TextField source="id"/>

                    <PartyField source="creditorId" partyType="LEGAL_ENTITY" label="Debt Investor" />
                    <EnumDictField enum={NDAStatus} source="ndaStatusCode" label="NDA"/>
                    <BooleanField source="loiIsAccepted" label="Access to Full DD"/>
                    <BooleanField source="isCreditorVerified" label="Is Debt Investor verified?"/>
                    <FunctionField label="Confirmed by Borrower"
                                   render={record => {
                            if (!loanApplication.isBorrowerConfirmationRequired)
                                return "Not required";
                            return <BooleanField source="isConfirmedByBorrower" label="Confirmed by Borrower"/>
                        }
                    }/>
                    <FunctionField label="NDA from Debt Investor" render={record => {
                        return record.ndaFromCreditorDoc
                            ? <Link
                                href={`/api/v1/loanApplications/${record.loanApplicationId}/creditors/${record.creditorId}/nda/${record.ndaFromCreditorDocId}`}>
                                {record.ndaFromCreditorDoc.filename}
                            </Link>
                            : "-"
                        }
                    }/>
                    <FunctionField label="NDA from Monivolt" render={record => {
                        return record.ndaFromMonivoltDoc
                            ? <Link
                                href={`/api/v1/loanApplications/${record.loanApplicationId}/creditors/${record.creditorId}/nda/${record.ndaFromMonivoltDocId}`}>
                                {record.ndaFromMonivoltDoc.filename}
                            </Link>
                            : "-"
                        }
                    }/>
                    <EnumDictField enum={LoanApplicationCreditorStatus} source="statusCode" />
                    <FunctionField render={(record) => {
                        return (
                            <Button onClick={() => showChatModalDialog({opportunity: record})}>Chat</Button>
                        );
                    }} />
                    <ActionMenuButton/>
                </Datagrid>
            </List>
            {ChatModalDialog}
        </>
    );
}

export default LoanApplicationCreditorRelList;

LoanApplicationCreditorRelList.propTypes = {
  loanApplication: PropTypes.object.isRequired,
}