import React, {useState} from "react";
import {
    ArrayInput, BulkDeleteWithConfirmButton,
    Button,
    Create,
    Datagrid,
    FormDataConsumer,
    FunctionField,
    List,
    ListContextProvider,
    NumberInput,
    RecordContextProvider,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleFormIterator,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useDataProvider,
    useList,
    useNotify, usePermissions,
    useRecordContext,
    useRefresh,
    useShowController, useTheme,
} from 'react-admin';
import Resources from "../Resources";
import {PartyField} from "./controls/PartyField";
import DateTimeFieldTZ from "./controls/DateFieldTZ";

import ActionButton from "./controls/ActionButton";
import AuthenticatedBulkDeleteAction from "./controls/AuthenticatedBulkDeleteAction";
import CreditorApplicationDetails from "./entityDetails/CreditorApplicationDetails";
import Grid from "@mui/material/Grid";
import {PARTY_TYPE} from "../common/constants";
import {CreditorApplicationStatus} from "./dicts/CreditorApplicationEnums";
import LegalEntityDetails from "./entityDetails/LegalEntityDetails";
import PersonDetails from "./entityDetails/PersonDetails";
import CreditorFundingCriteriasDialog from "./creditors/CreditorFundingCriteriasDialog";
import LegalEntityInput from "./controls/LegalEntityInput";
import UserLoginInput from "./controls/UserLoginInput";
import {useQuery} from "@tanstack/react-query";
import Typography from "@mui/material/Typography";
import PersonEmailField from "./controls/PersonEmailField";
import CallReportListPage from "./callReports/CallReportListPage";
import {PRODUCT_APPLICATION_TYPES} from "./dicts/PartyEnums";
import {CurrencyType} from "./dicts/ExchangeEnums";
import {CurrencyInput} from "./controls/CurrencyInput";
import CountryInput from "./controls/CountryInput";
import DictInput from "./controls/DictInput";
import {FormattedNumberInput} from "./controls/FormattedNumberInput";
import Chip from '@mui/material/Chip';
import DeactivateCreditorApplicationDialog from "./creditors/DeactivateCreditorApplicationDialog";
import {haveRole, onError} from "../common/utils";
import {AdminPortalRoles} from "./dicts/Security";
import AvailableOpportunities from "./creditors/AvailableOpportunities";
import RelevantLoanApplications from "./creditors/RelevantLoanApplications";

export const CreditorApplicationList = () => {
    const { permissions } = usePermissions();
    const [isCriteriaDialogOpened, setCriteriaDialogOpened] = useState();
    const [creditorIdToUpdateCriteria, setCreditorIdToUpdateCriteria] = useState();
    const [creditorNameToUpdateCriteria, setCreditorNameToUpdateCriteria] = useState();
    const [currencyRequired, setCurrencyRequired] = useState();
    const [currency, setCurrency] = useState();
    const [theme] = useTheme();

    const openCriteriaDialog = (creditorId, creditorName) => {
        setCreditorIdToUpdateCriteria(creditorId);
        setCreditorNameToUpdateCriteria(creditorName);
        setCriteriaDialogOpened(true);
    }

    const closeCriteriaDialog = () => {
        setCriteriaDialogOpened(false);
    }

    const onChangeAmountsFields = (event) => {
        setCurrencyRequired(event.target.value)
    }

    const creditorAppListFilters = [
        <TextInput source="fullCompanyName" label="Organisation" resettable alwaysOn />,
        <SelectInput source="statusCode" label="Status" alwaysOn
                     choices={Array.from(Object.keys(CreditorApplicationStatus),
                         (key) => ({
                             id: CreditorApplicationStatus[key].code,
                             name: CreditorApplicationStatus[key].label
                         }))}
                     resettable
        />,
        <CurrencyInput source="currency" currencyTypeCode={CurrencyType.FIAT.code} label="Currency"
                       alwaysOn={!!currencyRequired} required={!!currencyRequired} sx={{width: "250px"}}
                       onChange={v => setCurrency(v)}
        />,
        <FormattedNumberInput label="Loan Amount" source="loanAmount" onChange={onChangeAmountsFields}/>,
        <FormattedNumberInput source="revenue" label="Revenue" onChange={onChangeAmountsFields}/>,
        <FormattedNumberInput source="ebitda" label="EBITDA" onChange={onChangeAmountsFields}/>,
        <FormattedNumberInput source="enterpriseValue" label="Enterprise Value" onChange={onChangeAmountsFields}/>,
        // <NumberInput source="loanAmountSweetSpotMin" label="Loan Amount Sweet Spot Min"/>,
        // <NumberInput source="loanAmountSweetSpotMax" label="Loan Amount Sweet Spot Max"/>,
        <NumberInput source="term" label="Loan Term (in months)"/>,
        <NumberInput source="irrTo" label="Returns From, IRR, %"/>,
        <NumberInput source="irmmTo" label="Returns From, Money Multiple"/>,
        <DictInput label="Borrower Types" source="borrowerTypes" reference={Resources.DICT_BORROWER_TYPES.name}/>,
        <DictInput label="Business Stages" source="businessStages" reference={Resources.DICT_BUSINESS_STAGES.name}/>,
        <DictInput label="Loan Types" source="loanTypes" reference={Resources.DICT_LOAN_TYPES.name}/>,
        <DictInput label="Use of Funds" source="useOfFunds" reference={Resources.DICT_USE_OF_FUNDS.name}/>,
        <DictInput label="Loan Structures" source="loanStructures" reference={Resources.DICT_LOAN_STRUCTURES.name}/>,
        <DictInput label="Sectors" source="sectors" reference={Resources.DICT_SECTORS.name}/>,
        <DictInput label="Regions" source="regions" reference={Resources.DICT_REGIONS.name}/>,
        <CountryInput source="country" label="Loan Country"/>,
        // <TextInput source="comments" label="Comments" alwaysOn sx={{width: "400px"}}/>,
    ];

    const deactivatedRowStyle = (record, index) => {
        if (theme === 'light') {
            return {
                backgroundColor:
                    record.statusCode === CreditorApplicationStatus.DEACTIVATED.code
                        ? '#efefef'
                        : 'white'
            };
        } else {
            return {
                backgroundImage:
                    record.statusCode === CreditorApplicationStatus.DEACTIVATED.code
                        ? '#efefef'
                        : 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
            }
        }
    };

    return (
        <>
            <List sort={{field: "id", order: "DESC"}} filters={creditorAppListFilters} perPage={50}>
                <Datagrid rowClick={false}
                          data-testid="creditor-apps-list-grid"
                          bulkActionButtons={haveRole(permissions, AdminPortalRoles.BACKOFFICE_SENIOR_MGMT)
                              ? <BulkDeleteWithConfirmButton confirmContent="You will not be able to recover this records. Are you sure?"/>
                              : false}
                          rowSx={deactivatedRowStyle}>
                    <TextField source="id"/>
                    <PartyField source="legalEntityId" label="Legal Entity" partyType={PARTY_TYPE.LEGAL_ENTITY} sortable={false}/>
                    {/*<DateField source="createdDate" showTime/>*/}
                    <DateTimeFieldTZ source="createdDate" label={"Created Date (London)"}/>
                    {/*<TextField source="loanProceeds" />*/}
                    <FunctionField label="Status" sortable={false} render={(record) => (
                        <Chip label={CreditorApplicationStatus[record.statusCode].label}
                              color={CreditorApplicationStatus[record.statusCode].color}
                              sx={{borderRadius: "12px"}} size="small"
                              title={record.statusCode === CreditorApplicationStatus.DEACTIVATED.code && record.deactivateReason
                                  ? record.deactivateReason : ""}
                        />)}
                    />
                    <TextField source="initiatorLogin"/>
                    {/*<EditButton/>*/}
                    <ShowButton data-testid="creditor-app-details-link"/>

                    <FunctionField render={(record) => (
                        <>
                            {record.statusCode === CreditorApplicationStatus.APPROVED.code &&
                                <Button label="Investment Criteria" onClick={() => openCriteriaDialog(record.legalEntityId, record.legalEntity.fullCompanyName)} /> }
                        </>
                    )}/>

                </Datagrid>
            </List>
            {isCriteriaDialogOpened && creditorIdToUpdateCriteria != null && creditorIdToUpdateCriteria > 0
                && <CreditorFundingCriteriasDialog close={closeCriteriaDialog} creditorId={creditorIdToUpdateCriteria}
                                                   creditorName={creditorNameToUpdateCriteria}
                                                   isOpened={isCriteriaDialogOpened} />}
        </>
    )
}

/*
export const CreditorApplicationEdit = () => {
    // const loanTypesHook = useGetList(Resources.DICT_LOAN_TYPES.name);
    // const loanTypes = loanTypesHook.data;
    // console.log("loanTypes", loanTypes)
    return (
        <Edit>
            <SimpleForm>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Typography fontSize="12px">Created Date</Typography>
                        <DateField source="createdDate" showTime/>
                    </Grid>
                    <Grid item xs={2}>
                        <DictInput label="Loan Type" source="loanTypeCode"
                                   reference={Resources.DICT_LOAN_TYPES.name}
                                   fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <NumberInput source="loanTerm" fullWidth/>
                    </Grid>
                    <Grid item xs={5}/>
                    <Grid item xs={2}>
                        <TextInput source="loanAmount" fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <CurrencyInput label="Currency" source="currency"
                                       reference={Resources.DICT_CURRENCIES.name}
                                       fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}/>
                    <Grid item xs={2}>
                        <TextInput source="creditInterest" fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput source="loanProceeds" fullWidth/>
                    </Grid>
                    <Grid item xs={5}/>
                    <Grid item xs={2}>
                        <TextInput source="collateralAmount" fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <CurrencyInput label="Collateral Currency" source="collateralCurrencyCode"
                                   reference={Resources.DICT_CURRENCIES.name}
                                   fullWidth
                        />
                    </Grid>
                    <Grid item xs={5}/>
                    <Grid item xs={2}>
                        <TextInput source="initiatorLogin" fullWidth disabled/>
                    </Grid>
                    <Grid item xs={2}>
                        <DictInput label="Application Status" source="statusCode"
                                   reference={Resources.DICT_LOAN_APPLICATION_STATUSES.name}
                                   fullWidth
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};*/

const CreditorApplicationsShowActions = (props) => {
    const record = props.record;
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const [deactivateDialogOpened, setDeactivateDialogOpened] = useState(false);

    const openDeactivateDialog = () => {
        setDeactivateDialogOpened(true)
    }

    const closeDeactivateDialog = () => {
        setDeactivateDialogOpened(false)
    }

    const onSuccessDeactivation = (loan) => {
        notify('Debt Investor has been deactivated.', {type: 'success', autoHideDuration: 2000});
        refresh();
        closeDeactivateDialog();
    }
    const deactivateDebtInvestor = (data) => {
        dataProvider['doBodyAction'](Resources.CREDITOR_APPLICATIONS.name, {
            action: Resources.CREDITOR_APPLICATIONS.actions.DEACTIVATE.name,
            id: record.id,
            method: 'PUT',
            body: data,
        }).then(({data}) => {
            if (data.success) {
                onSuccessDeactivation(data);
            } else {
                const msg =
                    data.errMessage
                        ? data.errMessage
                        : "System error";
                onError({message: msg}, notify)
            }
        }).catch(error => {
            onError(error, notify);
        });
    };

    return record && <TopToolbar>
        {/*<EditButton/>*/}
        {record.statusCode === CreditorApplicationStatus.SUBMITTED.code &&
            <ActionButton color="primary" label="Analyse" action="process" record={record} method="PUT"
                          resource={Resources.CREDITOR_APPLICATIONS.name}/>}
        {record.statusCode === CreditorApplicationStatus.PROCESSING.code &&
            <ActionButton color="primary" label="Approve" action="approve" record={record} method="PUT"
                          resource={Resources.CREDITOR_APPLICATIONS.name}/>}
        {record.statusCode === CreditorApplicationStatus.PROCESSING.code &&
            <ActionButton color="primary" label="Reject" action="reject" record={record} method="PUT"
                          resource={Resources.CREDITOR_APPLICATIONS.name}/>}
        {record.statusCode === CreditorApplicationStatus.APPROVED.code &&
            <Button color="primary" label="Deactivate" onClick={openDeactivateDialog}/>}
        {record.statusCode === CreditorApplicationStatus.DEACTIVATED.code &&
            <ActionButton color="primary" label="Activate" action="activate" record={record} method="PUT"
                          resource={Resources.CREDITOR_APPLICATIONS.name}/>}
        <DeactivateCreditorApplicationDialog close={closeDeactivateDialog}
                                             record={record}
                                             open={deactivateDialogOpened}
                                             submit={deactivateDebtInvestor}/>
    </TopToolbar>
}

export const CreditorApplicationShow = () => {
    const {record} = useShowController();

    const contactPersonsListContext = useList({ data: record?.contactPersons });
    if (!record) return null;
    const id = record.id

    const partyId = record.legalEntityId

    return (
        <RecordContextProvider value={record}>
            <Show actions={<CreditorApplicationsShowActions record={record}/>}>
                <TabbedShowLayout>
                    <Tab label="Application Info">
                        <CreditorApplicationDetails record={record}/>
                    </Tab>
                    <Tab label="Initiator (Credit Officer)">
                        {record.initiator && <PersonDetails record={record.initiator}/>}
                        {!record.initiator &&
                            <Typography className="no-data-found">
                                No initiator. Possibly application was created by BackOffice operator
                            </Typography>
                        }
                    </Tab>
                    <Tab label="Legal Entity">
                        <LegalEntityDetails record={record.legalEntity}/>
                    </Tab>
                    <Tab label="Contact Persons">
                        <ListContextProvider value={contactPersonsListContext}>
                            <Datagrid rowClick={false} bulkActionButtons={false}>
                                <TextField source="id"/>
                                <TextField source="firstName"/>
                                <TextField source="lastName"/>
                                <PersonEmailField source="email" verifiedSource="emailVerified" label="Email" />
                                <TextField source="userLogin"/>
                                <TextField source="phoneNumber"/>
                                <TextField source="title"/>
                            </Datagrid>
                        </ListContextProvider>
                    </Tab>
                    <Tab label="Meeting Notes">
                        {id && partyId &&
                            <CallReportListPage resource={Resources.CALL_REPORT.name}
                                                filter={{orInitiatorCompany: partyId,
                                                    orApplicationType: PRODUCT_APPLICATION_TYPES.CAPITAL_PARTNER_APPLICATION.code,
                                                    orApplicationId: id
                                                }}
                                                hideFilters
                                                defaultCallReportData={{productApplications:[{
                                                        applicationType: PRODUCT_APPLICATION_TYPES.CAPITAL_PARTNER_APPLICATION.code,
                                                        applicationId: id,
                                                        partyType: PARTY_TYPE.LEGAL_ENTITY,
                                                        partyId: partyId
                                                    }]}}
                            />
                        }
                    </Tab>
                    <Tab label="Opportunities (Shared)">
                        <AvailableOpportunities creditorApplication={record}/>
                    </Tab>
                    <Tab label="Opportunities (All Relevant)">
                        <RelevantLoanApplications creditorApplication={record}/>
                    </Tab>
                </TabbedShowLayout>
            </Show>
        </RecordContextProvider>
    )
}

export const CreditorApplicationCreate = () => {
    const dataProvider = useDataProvider();
    const [creditorId, setCreditorId] = useState(null);

    return (
        <Create>
            <SimpleForm>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <LegalEntityInput source="legalEntityId" label="Debt Investor" onChange={setCreditorId}/>
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        <ArrayInput source="contactPersons" fullWidth label="Credit Officers" >
                            <SimpleFormIterator fullWidth inline disableReordering disableRemove={false}>
                                <FormDataConsumer>
                                    {({formData, scopedFormData}) => {
                                        // console.log(creditorId)
                                        const { data, isInitialLoading, error } = useQuery({
                                            queryKey: ['organisationContacts', creditorId],
                                            queryFn: () => {
                                                return dataProvider['doAction'](Resources.LEGAL_ENTITY.name, {
                                                    action: Resources.LEGAL_ENTITY.subrequests.PERSONS.name,
                                                    id: creditorId,
                                                    method: 'GET'
                                                });
                                            }, enabled: !!creditorId
                                        });
                                        const contacts = data?.data;
                                        return (
                                            <>
                                                <SelectInput label="Contact Person" validate={required()}
                                                             source="id"
                                                             choices={contacts ? contacts : []}
                                                             optionText={(record) => record.id + ": " + record.firstName + " " + record.lastName}
                                                             fullWidth
                                                />
                                            </>
                                        )}}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    <Grid item xs={8}>
                    </Grid>
                    <Grid item xs={4}>
                        <UserLoginInput source="initiatorId" fullWidth/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};