import * as React from "react";
import {useEffect, useId, useState} from "react";
import {Typography} from "@mui/material";
import {
    BarPlot,
    ChartsGrid,
    ChartsLegend,
    ChartsTooltip,
    ChartsXAxis,
    ChartsYAxis,
    LinePlot,
    MarkPlot,
    ResponsiveChartContainer
} from "@mui/x-charts";
import {mangoFusionPalette} from "@mui/x-charts/colorPalettes";
import {formatAmount, getHumanReadableAmount} from "../../common/utils";

export const MonthlyVolumeOfNewDeals = (props) => {
    const {data, label, cumulative, currency} = props
    const chartHeight = 400
    const id = useId();
    const [totalChartData, setTotalChartData] = useState([])

    useEffect(() => {
        if (!data)
            return;

        const totalSeries = [];
        let yValues1, yValues2, yValues3;
        if (cumulative) {
            yValues1 = data.cumulativeVolumeOfNewDeals;
            yValues2 = data.cumulativeNumberOfDeals;
            yValues3 = data.cumulativeAverageDealSize;
        } else {
            yValues1 = data.totalVolumeOfNewDeals;
            yValues2 = data.totalNumberOfDeals;
            yValues3 = data.averageDealSize;
        }
        if (yValues1?.length) {
            const xAxis = data.xlabels;
            for (let i = 0; i < yValues1.length; i++) {
                totalSeries.push({month: xAxis[i], total: yValues1[i], avg: yValues3[i], count: yValues2[i]})
            }
            // console.log("totalSeries", totalSeries)
            setTotalChartData(totalSeries);
        }
    }, [data]);

    const chartSetting = {
        xAxis: [{
            scaleType: 'band',
            dataKey: 'month',
            valueFormatter: (code, context) => {
                const idx =  data.xlabels?.indexOf(code);
                let count = 0;
                if (cumulative) {
                    count = data.cumulativeNumberOfDeals?.length > idx ? data.cumulativeNumberOfDeals[idx] : 0;
                } else {
                    count = data.totalNumberOfDeals?.length > idx ? data.totalNumberOfDeals[idx] : 0;
                }
                return context.location === 'tick' ? code : `${code}, ${count} Deals`
            },
            tickLabelStyle: {
                angle: 45, dominantBaseline: 'hanging', textAnchor: 'start',
            },
            labelStyle: {
                transform: 'translateY(15px)',
            }
        }],
        height: chartHeight,
    };

    const formatVolume = (v) => {
        return formatAmount(currency, v, {maximumFractionDigits: 0})
    }

    return (<>
            <div className="header">
                <Typography variant="h12" sx={{width: "100%"}}>
                    {label}
                </Typography>
            </div>
            {totalChartData?.length && <ResponsiveChartContainer
                dataset={totalChartData}
                series={[
                    {type: 'bar', dataKey: 'total', label: 'Total volume of new deals',
                        valueFormatter: (v) => (v === null ? '' : formatVolume(v))},
                    {type: 'line', dataKey: 'avg', label: 'Average deal size', color: "red", yAxisId: 'rightAxis', curve: 'linear',
                        valueFormatter: (v) => (v === null ? '' : formatVolume(v))},
                    // {type: 'line', dataKey: 'count', label: 'Total number of new deals', yAxisId: 'countAxis', color: 'lightblue', curve: 'natural'},
                ]}
                yAxis={[{id: 'leftAxis'},{id: 'rightAxis'}/*,{id:'countAxis'}*/]}
                {...chartSetting}
                colors={mangoFusionPalette}
            >
                <ChartsGrid horizontal />
                <BarPlot/>
{/*
                <BarPlot barLabel={(item, context) => {
                    return `${totalChartData[item.dataIndex]?.count} Deals`
                }}/>
*/}

                <LinePlot />
                <MarkPlot />

                <ChartsXAxis />
                <ChartsYAxis axisId="leftAxis"
                             valueFormatter={(v, context) => context.location === 'tick' ? getHumanReadableAmount(v, currency) : formatVolume(v)}/>
                <ChartsYAxis
                    axisId="rightAxis"
                    position="right"
                    tickLabelStyle={{fill: "red"}}
                    valueFormatter={(v, context) => context.location === 'tick' ? getHumanReadableAmount(v, currency) : formatVolume(v)}
                />
                <ChartsTooltip />
                <ChartsLegend />
            </ResponsiveChartContainer>}
        </>)
}